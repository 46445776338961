import React from 'react';

import type { IconProps } from '@atlaskit/icon/types';
import Icon from '@atlaskit/icon';

const WorldGreenDotGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.8837 13.367C19.9601 12.9228 20 12.466 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C12.466 20 12.9228 19.9601 13.367 19.8837C13.1304 19.3023 13 18.6664 13 18C13 17.8036 13.0113 17.6099 13.0333 17.4195C12.9034 17.622 12.7713 17.7875 12.6409 17.9161C12.3763 18.1772 12.1606 18.25 12 18.25C11.8394 18.25 11.6237 18.1772 11.3591 17.9161C11.0913 17.6518 10.8157 17.2321 10.568 16.6543C10.1416 15.6593 9.84191 14.2977 9.76783 12.75L14.2322 12.75C14.1914 13.6024 14.0821 14.3983 13.921 15.1076C14.3759 14.4673 14.9778 13.9386 15.6776 13.5709C15.7028 13.3018 15.7216 13.0278 15.7337 12.75H18.4572C18.4469 12.8397 18.4348 12.9289 18.4208 13.0175C18.9344 13.0603 19.426 13.1807 19.8837 13.367ZM10.568 7.34569C10.1416 8.34074 9.84191 9.70234 9.76783 11.25L14.2322 11.25C14.1581 9.70234 13.8584 8.34074 13.432 7.34569C13.1843 6.7679 12.9087 6.34823 12.6409 6.08391C12.3763 5.82276 12.1606 5.75 12 5.75C11.8394 5.75 11.6237 5.82276 11.3591 6.08391C11.0913 6.34823 10.8157 6.7679 10.568 7.34569ZM15.7337 11.25L18.4572 11.25C18.1804 8.84094 16.5866 6.83252 14.4139 5.96299C14.5588 6.20973 14.6909 6.47541 14.8107 6.75481C15.328 7.96182 15.6587 9.53144 15.7337 11.25ZM5.5428 11.25L8.26628 11.25C8.34127 9.53144 8.67203 7.96182 9.18932 6.75481C9.30907 6.47541 9.44124 6.20974 9.58609 5.96299C7.41342 6.83252 5.81958 8.84094 5.5428 11.25ZM8.26628 12.75H5.5428C5.81958 15.1591 7.41342 17.1675 9.58609 18.037C9.44124 17.7903 9.30906 17.5246 9.18932 17.2452C8.67203 16.0382 8.34127 14.4686 8.26628 12.75Z"
			fill="currentColor"
		/>
		<circle cx="18" cy="18" r="3" fill="inherit" />
	</svg>
);

export const WorldGreenDotIcon = (props: IconProps) => (
	<Icon {...props} glyph={WorldGreenDotGlyph} />
);

import React from 'react';

import type { IconProps } from '@atlaskit/icon/types';
import Icon from '@atlaskit/icon';

const UnlockGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 5.5C10.6193 5.5 9.5 6.61929 9.5 8V11H16C17.1046 11 18 11.8954 18 13V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V13C6 11.8954 6.89543 11 8 11V8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8H14.5C14.5 6.61929 13.3807 5.5 12 5.5ZM8 12.5C7.72386 12.5 7.5 12.7239 7.5 13V18C7.5 18.2761 7.72386 18.5 8 18.5H16C16.2761 18.5 16.5 18.2761 16.5 18V13C16.5 12.7239 16.2761 12.5 16 12.5H8Z"
			fill="currentColor"
		/>
	</svg>
);

export const UnlockIcon = (props: IconProps) => <Icon {...props} glyph={UnlockGlyph} />;

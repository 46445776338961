import React from 'react';

import type { IconProps } from '@atlaskit/icon/types';
import Icon from '@atlaskit/icon';

const LockGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 5.5C10.6193 5.5 9.5 6.61929 9.5 8V11H14.5V8C14.5 6.61929 13.3807 5.5 12 5.5ZM16 11V8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8V11C6.89543 11 6 11.8954 6 13V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V13C18 11.8954 17.1046 11 16 11ZM8 12.5C7.72386 12.5 7.5 12.7239 7.5 13V18C7.5 18.2761 7.72386 18.5 8 18.5H16C16.2761 18.5 16.5 18.2761 16.5 18V13C16.5 12.7239 16.2761 12.5 16 12.5H8ZM11.25 17V14H12.75V17H11.25Z"
			fill="currentColor"
		/>
	</svg>
);

export const LockIcon = (props: IconProps) => <Icon {...props} glyph={LockGlyph} />;

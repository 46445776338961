import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';

import { ShareButtonPlaceholder } from './ShareButtonPlaceholder';
import { CopyLinkButtonPlaceholder } from './copy-link/CopyLinkButtonPlaceholder';
import { SHARE_BUTTON_PAGE_SEGMENT_LOAD } from './perf.config';
import type { ShareComponentProps } from './ShareButton';

export type { ShareContentType } from './ShareButton';
export { useShareToEntities } from './useShareToEntities';
export { ShareDialogIsAutoOpenStore } from './hooks/useShareDialogIsAutoOpenStore';
export type { ShareResponse } from './useShareToEntities';
export { SUPPORTED_CONTENT_TYPES } from './copy-link/useCopyContentLink';
export const CopyLinkButtonLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CopyLinkButton" */ './copy-link/CopyLink'))
			.CopyLink,
	loading: CopyLinkButtonPlaceholder,
});

const ShareButtonLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ShareButton" */ './ShareButton')).ShareButton,
	loading: ShareButtonPlaceholder,
});

export const ShareButton = (props: ShareComponentProps) => (
	<>
		<PageSegmentLoadStart
			metric={SHARE_BUTTON_PAGE_SEGMENT_LOAD}
			isCustomStart
			key={props.contentId}
		/>
		<ShareButtonLoader {...props} />
	</>
);

// NOTE: This is NOT a drop-in replacement for ShareButtonLoader
// Please check that you are passing the correct props
export const ShareButtonLiteLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ShareButtonLite" */ './ShareButtonLite'))
			.ShareButtonLite,
});

// Adding a separate Invite Button loader temporarily as a workaround for the custom placeholder not working in SSR
export const InviteButton = LoadableAfterPaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ShareButton" */ './ShareButton')).ShareButton,
});

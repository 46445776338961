import { isValidUrl } from './isValidUrl';
import { isValidEmoji } from './isValidEmoji';
import { sanitizeEmojiValue } from './transformer';

export const isValidEmojiOrFavicon = (emoji: string | undefined, type: string) => {
	/**
	 * Why do emojis need to be 'sanitized'? Originally emoji content properties
	 * (emoji-title-published, emoji-title-draft) were a part of a REST API
	 * where the value would be JSON and not just a string.  This is why emoji
	 * values come through with extra quotes and we have to clean that up.
	 * A URL with quotes is not a valid URL.
	 * e.g. '"https://www.youtube.com/s/desktop/1f2ae858/img/favicon_32x32.png"' vs.
	 * "https://www.youtube.com/s/desktop/1f2ae858/img/favicon_32x32.png"
	 */
	const sanitizedEmoji = sanitizeEmojiValue(emoji);
	const isEmbed = type === 'embed';
	const isEmbedWithoutEmoji = isEmbed && !sanitizedEmoji;
	return (
		(sanitizedEmoji && isValidEmoji(sanitizedEmoji)) ||
		(sanitizedEmoji && isEmbed && isValidUrl(sanitizedEmoji)) ||
		isEmbedWithoutEmoji
	);
};

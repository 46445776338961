/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import omit from 'lodash/omit';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';
import Spinner from '@atlaskit/spinner/spinner';
import Tooltip from '@atlaskit/tooltip';

import { ContentIcon } from '@confluence/icons/entry-points/ContentIcon';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const WebItemLink = styled('a', { shouldForwardProp: () => true })`
	margin-right: ${token('space.075', '6px')} !important;

	img {
		max-height: 100%;
		height: auto;
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
	}
`;

const WebItemTooltip = ({ tooltip, children }) => {
	if (tooltip) {
		return <Tooltip content={tooltip}>{(tooltipProps) => children(tooltipProps)}</Tooltip>;
	}

	return children({});
};

WebItemTooltip.propTypes = {
	/**
	 * Tooltip of the web item
	 */
	tooltip: PropTypes.string,
	children: PropTypes.func.isRequired,
};

const WebItemIcon = ({ classes, id, url, label, tooltip, onClick, style, icon, ...otherProps }) => {
	return (
		<WebItemTooltip tooltip={tooltip}>
			{(tooltipProps) => (
				<WebItemLink
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={classes}
					href={url}
					id={id}
					onClick={onClick}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={style}
					{...tooltipProps}
					{...otherProps}
				>
					{icon}
					{typeof label === 'string' ? <span dangerouslySetInnerHTML={{ __html: label }} /> : label}
				</WebItemLink>
			)}
		</WebItemTooltip>
	);
};

WebItemIcon.propTypes = {
	/**
	 * classes to use for the component
	 */
	classes: PropTypes.string,
	/**
	 * id passed from WebItem
	 */
	id: PropTypes.string,
	/**
	 * Route where the web item will point
	 */
	url: PropTypes.string,
	/**
	 * Label of the web item
	 */
	label: PropTypes.string,
	/**
	 * Tooltip of the web item
	 */
	tooltip: PropTypes.string,
	/**
	 * Handler for clicks
	 */
	onClick: PropTypes.func,
	/**
	 * WebItem styles
	 */
	style: PropTypes.object,
	/**
	 * Icon obj
	 */
	icon: PropTypes.object,
};

export class WebItem extends PureComponent {
	static propTypes = {
		/**
		 * Classnames. Note that WebItem no longer assumes that it should render as a button
		 * And as such you may need to explicitly pass the `aui-button` class here.
		 */
		className: PropTypes.string,
		/**
		 * id
		 */
		id: PropTypes.string,
		/**
		 * Route where the web item will point
		 */
		url: PropTypes.string,
		/**
		 * Type of the button
		 */
		type: PropTypes.string,
		/**
		 * Label of the web item
		 */
		label: PropTypes.string,
		/**
		 * Tooltip of the web item
		 */
		tooltip: PropTypes.string,
		/**
		 * Handler for clicks
		 */
		onClick: PropTypes.func,
		/**
		 * Contain parameters setted in the descriptor
		 */
		params: PropTypes.object,
		/**
		 * Doesn't render icons when true
		 */
		disableIcon: PropTypes.bool,
		/**
		 * icon information used for absolute images
		 */
		iconInfo: PropTypes.shape({
			path: PropTypes.string,
			width: PropTypes.number,
			height: PropTypes.number,
		}),
		/**
		 * Show spinner instead of an icon if equals true
		 */
		spinner: PropTypes.bool,
		/**
		 * WebItem styles
		 */
		style: PropTypes.object,
		/**
		 * Icon styles
		 */
		iconStyle: PropTypes.object,
		iconSize: PropTypes.string,
	};

	static defaultProps = {
		spinner: false,
		iconStyle: {},
		style: {},
		iconSize: 'small',
	};

	getIcon() {
		const { disableIcon, params, iconStyle, spinner, iconInfo, label, iconSize } = this.props;
		let auiIconClass = params && params.iconClass;

		if (disableIcon) {
			return null;
		}

		if (spinner) {
			return <Spinner testId="spinner" size="small" />;
		}

		if (iconInfo && iconInfo.path) {
			return (
				<img
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={iconStyle}
					src={iconInfo.path}
					width={iconInfo.width}
					height={iconInfo.height}
					alt={label}
					title={label}
				/>
			);
		}

		if (auiIconClass) {
			if (auiIconClass.indexOf('aui-iconfont-') === -1) {
				auiIconClass = `aui-iconfont-${auiIconClass}`;
			}
			return (
				<ContentIcon
					iconSize={iconSize}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={auiIconClass}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={iconStyle}
				/>
			);
		}

		return null;
	}

	render() {
		const { className, id, url, type, label, tooltip, onClick, style, ...otherProps } = this.props;
		const buttonType = type === 'primary' ? 'aui-button-primary' : '';
		const classes = classnames(className, buttonType);
		const icon = this.getIcon();
		//react complains about these extra props but we still need to pass otherProps (e.g. data-jira-metadata-count)
		const webItemIconProps = omit(
			{
				classes,
				id,
				url,
				label,
				tooltip,
				onClick,
				style,
				icon,
				...otherProps,
			},
			['disableIcon', 'iconStyle', 'spinner', 'iconInfo', 'iconSize'],
		);

		return <WebItemIcon {...webItemIconProps} />;
	}
}

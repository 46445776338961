import { LoadableAfterPaint } from '@confluence/loadable';

export const DraftStatus = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-banner-itemsdraft-statusDraftStatus" */ './banner-items/draft-status/DraftStatus'
			)
		).DraftStatus,
});
export const ScheduledStatus = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-banner-itemsscheduled-statusScheduledStatus" */ './banner-items/scheduled-status/ScheduledStatus'
			)
		).ScheduledStatus,
});
